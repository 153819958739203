// invoke sdk based method to pass cleverTap events to client
// separate methods for normal and error based events

export function invokeErrorCallback(errorObj) {
  // { errorTitle: string, errorDesc: string }
  console.error('invokeErrorCallback', errorObj);
  if (window.Android?.errorCallback)
    window.Android.errorCallback(JSON.stringify(errorObj));
  else if (window.webkit?.errorCallback)
    window.webkit.errorCallback(JSON.stringify(errorObj));
  else if (window.ReactNativeWebView?.errorCallback)
    window.ReactNativeWebView.errorCallback(JSON.stringify(errorObj));
}

export function invokeEventCallback(eventName, props) {
  // {eventName: string, props: object}
  console.log('invokeEventCallback', eventName, props);
  if (window.Android?.visitCallback)
    window.Android.visitCallback(JSON.stringify({ eventName, props }));
  else if (window.webkit?.visitCallback)
    window.webkit?.visitCallback(JSON.stringify({ eventName, props }));
  else if (window.ReactNativeWebView?.visitCallback)
    window.ReactNativeWebView?.visitCallback(
      JSON.stringify({ eventName, props })
    );
}
