import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  loginPhonepe,
  loginMagicLink,
  updateLoginState,
} from '../../actions/index';
import { Text, BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import Modal from '../common/Modal';
import Carousel from '../../components/common/Carousel';
import LoadingComponent from '../../components/common/LoadingComponent';

import heroImage1 from '../../images/onboarding/hero-1.svg';
import heroImage2 from '../../images/onboarding/hero-2.svg';
import heroImage3 from '../../images/onboarding/hero-3.svg';
import heroImage4 from '../../images/onboarding/hero-4.svg';
import { logEvent } from '../../utils/logEvent';
import { initializeAmplitudeClient } from '../../services/analytics';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  align-self: stretch;
  overflow-x: hidden;
`;

const BannerImageContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
`;

const HeroImage = styled.img`
  width: 50%;
  max-width: 12.5rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #4c4772;
  margin-top: 2.25rem;
  text-align: center;
`;

const SubtitleText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #706e96;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

class OnboardingHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingModal: false,
    };
  }

  componentDidMount = () => {
    const { dispatch, history, location, loginState } = this.props;
    window.baseLandingUrl = window.location.href;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const magicLink = searchParams.get('mluib7c');

    if (magicLink) {
      logEvent('Onboarding Magic Code Applied');
      this.setState({ showLoadingModal: true });
      dispatch(updateLoginState('LOGGED_OUT'));
      dispatch({
        type: 'UNSET_USER_INFO',
      });
      dispatch(loginMagicLink(magicLink, history, searchParams.get('tab')));
    } else if (loginState === 'LOGGED_IN') {
      logEvent('Onboarding User Logged In');
      history.replace('/home');
    } else if (loginState === 'ONE_LAST_STEP') {
      logEvent('Onboarding One Last Step Screen');
      history.push('/one-last-step');
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      window.gtag('event', 'screen_view', {
        screen_name: 'Intro Screen',
      });
      logEvent('Onboarding Intro Screen');
      window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
        .then((sdk) => {
          if (sdk.isMethodSupported('fetchGrantToken')) {
            return sdk.fetchGrantToken().then((res) => {
              this.setState({
                showLoadingModal: true,
              });
              dispatch(loginPhonepe(res.grantToken, history, location.search));
            });
          }
        })
        .catch((err) => {
          // either phonepe SDK does not exist, or something went wrong fetching grant token
          console.log(err);
        });
    }
  };

  onNext = () => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-753807922/L-FUCPC27ZgBELLkuOcC',
    });
    const { history, location } = this.props;
    history.push(`/login${location.search}`);
  };

  render() {
    const { showLoadingModal } = this.state;
    return (
      <FadeInTransition
        in={true}
        timeout={2000}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <CarouselContainer>
            <Carousel autoplay showDots>
              <BannerImageContainer
                width={`${Math.min(document.body.clientWidth, 800)}px`}
              >
                <HeroImage src={heroImage1} />
                <TitleText>
                  Consult with Health Specialists Anytime, Anywhere
                </TitleText>
                <SubtitleText>
                  Consult top Health Specialists over Video, Voice and Chat
                  anytime
                </SubtitleText>
              </BannerImageContainer>
              <BannerImageContainer
                width={`${Math.min(document.body.clientWidth, 800)}px`}
              >
                <HeroImage src={heroImage2} />
                <TitleText>Stay Motivated by Winning Rewards</TitleText>
                <SubtitleText>
                  Win Fitcoins by completing challenges and tracking your
                  fitness & food
                </SubtitleText>
              </BannerImageContainer>
              <BannerImageContainer
                width={`${Math.min(document.body.clientWidth, 800)}px`}
              >
                <HeroImage src={heroImage3} />
                <TitleText>
                  Your Personal Fitness Pal and Diet Assistant
                </TitleText>
                <SubtitleText>
                  Log your Meals, Track your Steps and Sleep. Get Personalised
                  Diet and Health Advice.
                </SubtitleText>
              </BannerImageContainer>
              <BannerImageContainer
                width={`${Math.min(document.body.clientWidth, 800)}px`}
              >
                <HeroImage src={heroImage4} />
                <TitleText>Book Appointments</TitleText>
                <SubtitleText>
                  Book an Offline Appointment with the Top Health Specialists
                </SubtitleText>
              </BannerImageContainer>
            </Carousel>
          </CarouselContainer>
          <PrimaryButton onTap={this.onNext}>
            <StyledButtonText>get started</StyledButtonText>
          </PrimaryButton>
          {showLoadingModal && (
            <Modal>
              <ModalContainer>
                <LoadingComponent color="white" />
              </ModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingHome);
