import { store } from '../store/index';
import { invokeErrorCallback } from './cleverTap';

export const showError = (err, title) => {
  let errorTitle = 'Something went wrong';
  let errorDesc =
    'We were unable to fulfill your request. Please try again later.';

  if (err.response && err.response.data && err.response.data.errorMessage) {
    errorDesc = err.response.data.errorMessage;
  } else if (err && err.data && err.data.errorMessage) {
    errorDesc = err.data.errorMessage;
  }

  if (err.response && err.response.data && err.response.data.errorHeader) {
    errorTitle = err.response.data.errorHeader;
  } else if (err && err.data && err.data.errorHeader) {
    errorTitle = err.data.errorHeader;
  }
  if (err?.config?.method?.toUpperCase() === 'OPTIONS') {
    // Preflight Request Error handling
    console.error('Preflight Request Error: ', err);
    return;
  }

  console.log({ err, errorTitle, errorDesc });
  invokeErrorCallback({
    errorTitle: title ? title : errorTitle,
    errorDesc,
  });
  store.dispatch({
    type: 'SHOW_ERROR',
    payload: {
      errorTitle: title ? title : errorTitle,
      errorDesc: errorDesc,
    },
  });
};
