import { initializeAmplitudeClient } from '../services/analytics';
import { invokeEventCallback } from './cleverTap';

const logEvent = (event, props) => {
  console.log(event, props);
  const analyticsClient = initializeAmplitudeClient();
  invokeEventCallback(event, props);
  analyticsClient.logEvent(event, props);
};

// category
const category = {
  BLOOD_PRESSURE_LOGGING: 'Blood Pressure Logging',
  BLOOD_SUGAR_LOGGING: 'Blood Sugar Logging',
  BOT: 'Bot',
  CHALLENGES: 'Challenges',
  CONSULTATION: 'Consultation',
  COVID: 'Covid',
  FITCOIN: 'Fitcoin',
  GMC: 'GMC',
  HRA: 'HRA',
  HEALTH: 'Health',
  IBM: 'IBM',
  INVITATION: 'Invitation',
  LABS: 'Labs',
  MEAL_LOGGING: 'Meal Logging',
  MEDICINE: 'Medicine',
  OPD: 'OPD',
  OFFLINE_APPOINTMENTS: 'Offline Appointments',
  ONBOARDING: 'Onboarding',
  ONLINE_CONSULTATIONS: 'Online Consultations',
  PERMISSIONS: 'Permissions',
  PERSONAL: 'Personal',
  REIMBURSEMENTS: 'Reimbursements',
  SLEEP_TRACKING: 'Sleep Tracking',
  SOCIAL_FEED: 'Social Feed',
  STEP_SYNCING: 'Step Syncing',
  TABS: 'Tabs',
  VIDEO_PRODUCT: 'Video Product',
  VISIT_PRIME: 'Visit Prime',
  WATER_TRACKING: 'Water Tracking',
  WEIGHT_LOGGING: 'Weight Logging',
  FITNESS: 'Fitness',
};

// analytics screen
const screenName = {
  ACCOUNTS_SCREEN: 'Accounts Screen',
  ADD_A_REIMBURSEMENT_REQUEST: 'Add A Reimbursement Request',
  ADD_ADDRESS_SCREEN: 'Add Address Screen',
  ADD_COMMENT: 'Add Comment',
  ADD_FOOD: 'Add Food',
  ADD_OPD_REIMBURSEMENTS_REQUEST: 'Add OPD Reimbursements Request',
  ADD_PATIENT_DETAIL_SCREEN: 'Add Patient Detail Screen',
  ADD_WATER: 'Add Water',
  AVAILABLE_TASKS_SCREEN: 'Available Tasks Screen',
  BIO_METRIC_AUTH_SCREEN: 'Bio Metric Auth Screen',
  BIOMETRIC_PERMISSION_DISABLED: 'Biometric Permission Disabled',
  BIOMETRIC_PERMISSION_ENABLED: 'Biometric Permission Enabled',
  BLOOD_SUGAR_CARD: 'Blood Sugar Card',
  BOOK_CLINIC_APPOINTMENT: 'Book Clinic Appointment',
  BOOK_DOCTOR_APPOINTMENT: 'Book Doctor Appointment',
  BOOK_FINANCIAL_ADVISOR: 'Book Financial Advisor',
  BOOK_LAB_TESTS: 'Book Lab Tests',
  BOOK_LEGAL_ADVISOR: 'Book Legal Advisor',
  BOOK_NUTRITIONIST_CONSULTATION: 'Book Nutritionist Consultation',
  BOT_SCREEN: 'Bot Screen',
  BOT_B2B_CONNECT_SCREEN: 'Bot/B2B Connect Screen',
  BP_DETAILED_SCREEN: 'BP Detailed Screen',
  BP_LOG_SCREEN: 'BP Log Screen',
  BS_DETAILED_SCREEN: 'BS Detailed Screen',
  BS_LOG_SCREEN: 'BS Log Screen',
  BUY_OPD_SCREEN: 'Buy OPD Screen',
  CALORIES_BURNT_DETAILED_SCREEN: 'Calories Burnt Detailed Screen',
  CAMERA_CLICKED: 'Camera Clicked',
  CANCELLATION_BLOCKER_SCREEN: 'Cancellation Blocker Screen',
  CHALLENGECARD_CLICK: 'Challengecard Click',
  CHANGE_ADDRESS: 'Change Address',
  CHANGE_LOCATION: 'Change Location',
  CHANGE_LOCATION_SCREEN: 'Change Location Screen',
  CHANGE_SLOT: 'Change Slot',
  CHANGE_WATER_GOAL_SCREEN: 'Change Water Goal Screen',
  CHECK_MY_SYMPTOMS: 'Check my Symptoms',
  CHOOSE_ADDRESS_SCREEN: 'Choose Address Screen',
  CHOOSE_LAB_SCREEN: 'Choose Lab Screen',
  CHOOSE_PATIENT_SCREEN: 'Choose Patient Screen',
  CLAIM_INTIMATION_SCREEN: 'Claim Intimation Screen',
  CLAIM_REIMBURSEMENT_DOCUMENT_UPLOAD_SCREEN:
    'Claim Reimbursement Document Upload Screen',
  CLAIM_REIMBURSEMENT_SCREEN: 'Claim Reimbursement Screen',
  CLAIMS_CARD: 'Claims Card',
  CLAIMS_HOME_SCREEN: 'Claims Home Screen',
  CLOSE_BOT_WITH_BACK_BUTTON: 'Close Bot with back button',
  CLOSE_BOT_WITH_CROSS: 'Close Bot with Cross',
  COMPLETED_TASKS_SCREEN: 'Completed Tasks Screen',
  CONSULT_A_COVID_SPECIALIST: 'Consult a COVID Specialist',
  CONSULT_A_GENERAL_PHYSICIAN: 'Consult a General Physician',
  CONSULT_A_NUTRITIONIST: 'Consult a Nutritionist',
  CONSULT_A_PSYCHOLOGIST: 'Consult a Psychologist',
  CONSULT_DOCTOR_ON_CALL: 'Consult doctor on call',
  CONSULT_DOCTOR_ON_PHONE: 'Consult Doctor on Phone',
  CONSULT_FOR_EMOTIONAL_WELLNESS: 'Consult for Emotional Wellness',
  CONSULT_FOR_FINANCIAL_WELLNESS: 'Consult for Financial Wellness',
  CONSULT_FOR_LEGAL_WELLNESS: 'Consult for Legal Wellness',
  CONSULT_FOR_PHYSICAL_WELLNESS: 'Consult for Physical Wellness',
  CONSULT_GENERAL_PHYSICIAN: 'Consult General Physician',
  CONSULT_NOW: 'Consult Now',
  CONSULT_SCREEN: 'Consult Screen',
  CONVERSATION_STARTERS: 'Conversation Starters',
  CORONA_ASSESSMENT_RETAKEN: 'Corona Assessment Retaken',
  CORONA_CHAT_INITIATED: 'Corona Chat Initiated',
  CORONA_CHAT_RESET: 'Corona Chat Reset',
  CORONA_CHECK_SYMPTOMS_LOADED: 'Corona Check Symptoms Loaded',
  CORONA_INTRODUCTION_LOADED: 'Corona Introduction Loaded',
  CREATE_POST_SCREEN: 'Create Post Screen',
  CREATE_TEAM: 'Create Team',
  CREATE_JOINTEAM: 'Create/jointeam',
  CROSS: 'Cross',
  DAILY: 'Daily',
  DASHBOARD_SCREEN: 'Dashboard Screen',
  DEFAULT_STEPS: 'Default Steps',
  DEFICIENCY_DOCUMENT_UPLOAD_SCREEN: 'Deficiency Document Upload Screen',
  DISCARD: 'DISCARD',
  DISMISS: 'Dismiss',
  DISTANCE_DETAILED_SCREEN: 'Distance Detailed Screen',
  DOCTOR_FULL_PROFILE_SCREEN: 'Doctor Full Profile Screen',
  DOCTOR_PROFILE_SCREEN: 'Doctor Profile Screen',
  DOCTORS_LIST_SCREEN: 'Doctors List Screen',
  DONE: 'Done',
  EARN_MORE_FITCOINS: 'Earn More FITCoins',
  EDIT_ACCOUNT_SCREEN: 'Edit Account Screen',
  EDIT_POST_SCREEN: 'Edit Post Screen',
  EDIT_SLEEP: 'Edit Sleep',
  EDIT_STEP_GOAL: 'Edit Step Goal',
  ENTER_EMAIL_SCREEN: 'Enter Email Screen',
  FEED: 'Feed',
  FEED_SCREEN: 'Feed Screen',
  FEEDBACK_SCREEN: 'Feedback Screen',
  FITNESS_HOME: 'Fitness Home',
  FORGOT_PASSWORD_SCREEN: 'Forgot Password Screen',
  GET_10_OFF: 'Get 10 OFF',
  GMC_CLAIM_STATUS_SCREEN: 'GMC Claim Status Screen',
  GOOGLE_FIT_PERMISSION_SCREEN: 'Google Fit Permission Screen',
  GRADIENT_CLICKED: 'Gradient Clicked',
  HB_DETAILED_SCREEN: 'HB Detailed Screen',
  HB_LOG_SCREEN: 'HB Log Screen',
  HEALTH_DATA_SCREEN: 'Health Data Screen',
  HEALTH_GOALS_CARD: 'Health Goals Card',
  HOW_IT_WORKS: 'How it Works',
  HR_REFERRAL_SCREEN: 'HR Referral Screen',
  HRA_STATUS_CARD: 'HRA Status Card',
  IMAGE_CLICKED: 'Image Clicked',
  INDIVIDUALLEADERBOARD: 'IndividualLeaderBoard',
  INSURE_CLAIMS_TAB: 'Insure Claims Tab',
  INSURE_IPD_TAB: 'Insure IPD Tab',
  INSURE_OPD_TAB: 'Insure OPD Tab',
  INSURE_SCREEN: 'Insure Screen',
  INTRO_SCREEN: 'Intro Screen',
  INVITE: 'Invite',
  INVITE_AND_EARN: 'Invite and Earn',
  INVITE_SCREEN: 'Invite Screen',
  LAB_CART_DETAILS: 'Lab Cart Details',
  LAB_DIGITIZATION_SCREEN: 'Lab Digitization Screen',
  LAB_HOME_SCREEN: 'Lab Home Screen',
  LAB_INVOICE_SCREEN: 'Lab Invoice Screen',
  LAB_ORDER_COMPLETED: 'Lab Order Completed',
  LAB_ORDER_PENDING: 'Lab Order Pending',
  LAB_PACKAGE_DETAILS_SCREEN: 'Lab Package Details Screen',
  LAB_PRESCRIPTION_SUBMITTED: 'Lab Prescription Submitted',
  LAB_SEARCH_SCREEN: 'Lab Search Screen',
  LAB_SLOT_SCREEN: 'Lab Slot Screen',
  LAB_SUMMARY_SCREEN: 'Lab Summary Screen',
  LAB_TEST_ORDER_SUMMARY_SCREEN: 'Lab Test Order Summary Screen',
  LAB_TEST_TRACKING_SCREEN: 'Lab Test Tracking Screen',
  LAB_VISIT_PRESCRIPTION_SCREEN: 'Lab Visit Prescription Screen',
  LIVE_AUDIO_PLAYER: 'Live Audio Player',
  LIVE_SESSIONS_CALENDAR: 'Live Sessions Calendar',
  LIVE_VIDEO_PLAYER: 'Live Video Player',
  LOGIN_OPTION_SCREEN: 'Login Option Screen',
  LOGIN_SCREEN: 'Login Screen',
  LOGOUT: 'Logout',
  MANAGE_TEAM: 'Manage Team',
  MEAL_LOGGED: 'Meal Logged',
  MEDICINE_DELIVERY: 'Medicine Delivery',
  MONTHLY: 'Monthly',
  MY_CLAIMS: 'My Claims',
  MY_CLINIC_APPOINTMENTS: 'My Clinic Appointments',
  MY_ONLINE_CONSULTS: 'My Online Consults',
  MY_ORDER: 'My Order',
  NEW_POST_FAB: 'New Post Fab',
  NUTRITION: 'Nutrition',
  NUTRITION_DETAILED_SCREEN: 'Nutrition Detailed Screen',
  OFFLINE_CONSULTATION_CARD: 'Offline Consultation Card',
  OFFLINE_DOCTOR_CHECKOUT_SCREEN: 'Offline Doctor Checkout Screen',
  OFFLINE_DOCTORLIST_SCREEN: 'Offline DoctorList Screen',
  OFFLINE_HOME_SCREEN: 'Offline Home Screen',
  ONE_LAST_STEP_SCREEN: 'One Last Step Screen',
  ONLINE_DOCTOR_CHECKOUT_SCREEN: 'Online Doctor Checkout Screen',
  ONLINE_DOCTORLIST_SCREEN: 'Online DoctorList Screen',
  ONLINE_HOME_SCREEN: 'Online Home Screen',
  OPEN_CAMERA: 'OPEN CAMERA',
  OPEN_FEED: 'Open Feed',
  OPEN_GALLERY: 'OPEN GALLERY',
  OPENLEADERBOARD: 'OpenLeaderboard',
  ORDER_MEDICINES: 'Order Medicines',
  OTHER_HEALTH_BENEFITS: 'Other Health Benefits',
  OTP_SCREEN: 'OTP Screen',
  OTP_VERIFIED_WHATSAPP: 'OTP Verified Whatsapp',
  PENDING_DIGITISATION: 'pending-digitisation',
  PERMISSION_BUTTON: 'Permission Button',
  PERMISSION_GIVEN: 'Permission Given',
  PHONE_NUMBER_SCREEN: 'Phone Number Screen',
  PHOTO_FILTER_SCREEN: 'Photo Filter Screen',
  PODCAST_HOME: 'Podcast Home',
  POLICY_ACTIVATED_SCREEN: 'Policy Activated Screen',
  POST: 'POST',
  POST_COMMENTS_SCREEN: 'Post Comments Screen',
  PRESCRIPTION_BLOCKER_SCREEN: 'Prescription Blocker Screen',
  PRODUCT_DESCRIPTION_SCREEN: 'Product Description Screen',
  REACTED: 'Reacted',
  RECORD_BLOOD_SUGAR: 'Record Blood Sugar',
  RECORD_WEIGHT: 'Record Weight',
  REFER_AND_EARN_OPD_SCREEN: 'Refer and Earn Opd Screen',
  REFER_AND_EARN_SCREEN: 'Refer and Earn Screen',
  REIMBURSEMENT: 'reimbursement',
  REIMBURSEMENT_STATUS_SCREEN: 'Reimbursement Status Screen',
  REIMBURSEMENTREQUEST: 'ReimbursementRequest',
  RELOAD_FEED: 'Reload Feed',
  REMOVE_MEAL: 'Remove Meal',
  REMOVE_REACTION: 'Remove Reaction',
  REMOVE_WATER: 'Remove Water',
  REQUEST_LOCATION_PERMISSION: 'Request Location Permission',
  REWARDS_PRODUCTS_SCREEN: 'Rewards Products Screen',
  REWARDS_PRODUCTS_TAB: 'Rewards Products Tab',
  SEE_ALL_YOUR_TASKS: 'See All Your Tasks',
  SEE_SUCCESSFUL_INVITES: 'See successful invites',
  SELECT_DISHES_SCREEN: 'Select Dishes Screen',
  SELECT_LAB_OPTION: 'Select Lab Option',
  SELECT_MEAL_TYPE: 'Select Meal Type',
  SHARED_APP_WITH_FRIENDS: 'Shared App with Friends',
  SIGNUP: 'Signup',
  SLEEP: 'Sleep',
  SLEEP_DETAILED_SCREEN: 'Sleep Detailed Screen',
  SLEEP_ENTRY_SCREEN: 'Sleep Entry Screen',
  SLEEP_LOG_SCREEN: 'Sleep Log Screen',
  SLOT_SELECTION_SCREEN: 'Slot Selection Screen',
  SLOT_STATUS_SCREEN: 'Slot Status Screen',
  SPECIALIST_CHAT_SCREEN: 'Specialist Chat Screen',
  STEP_GOAL_ENTRY_SCREEN: 'Step Goal Entry Screen',
  STEP_GOAL_LOG_SCREEN: 'Step Goal Log Screen',
  STEPS: 'Steps',
  STEPS_DETAILED_SCREEN: 'Steps Detailed Screen',
  SUMMARY_SCREEN: 'Summary Screen',
  SYNC_STEP_SCREEN: 'Sync Step Screen',
  TEAM_CREATED: 'Team Created',
  TEAM_CREATION_STARTED: 'Team Creation Started',
  TEAMLEADERBOARD: 'TeamLeaderBoard',
  TEAMMANAGE: 'TeamManage',
  TEAMMEMBERSLEADERBOARD: 'TeamMembersLeaderBoard',
  TEAMSCREEN: 'TeamScreen',
  TEMPLATE_CLICKED: 'Template Clicked',
  TODAY_WATER_LOG_DETAIL_SCREEN: 'Today Water Log Detail Screen',
  TRANSCRIPTIONREQUESTS: 'TranscriptionRequests',
  UPCOMING_EVENTS: 'Upcoming Events',
  UPCOMING_EVENTS_CARD: 'Upcoming Events Card',
  UPLOAD_PRESCRIPTION: 'Upload Prescription',
  USE_REWARDS: 'Use Rewards',
  USER_POSTS_SCREEN: 'User Posts Screen',
  VERTICAL_OFFLINE_SCREEN: 'Vertical Offline Screen',
  VIDEO_CALL_NOTIFIER_SCREEN: 'Video Call Notifier Screen',
  VIDEO_CALL_SCREEN: 'Video Call Screen',
  VIDEO_PLAYER: 'Video Player',
  VIEW_NETWORK_HOSPITALS: 'View Network Hospitals',
  VIEW_TEAM: 'View Team',
  VISIT_FOR_BUSINESS_SCREEN: 'Visit for Business Screen',
  VISIT_PRIME_ADD_RELATIVE: 'Visit Prime Add Relative',
  VISIT_PRIME_CHECKOUT_SCREEN: 'Visit Prime Checkout Screen',
  VISIT_PRIME_HEALTH_PLAN_LANDING_SCREEN:
    'Visit Prime Health Plan Landing Screen',
  VISIT_PRIME_HEALTH_PLAN_LANDING_SCREEN_TEST:
    'Visit Prime Health Plan Landing Screen Test',
  VISIT_PRIME_ORDER_COMPLETION_SCREEN: 'Visit Prime Order Completion Screen',
  VISIT_PRIME_PLAN_SELECTION_SCREEN: 'Visit Prime Plan Selection Screen',
  VISIT_PRIME_RELATIVE_SAVED: 'Visit Prime Relative Saved',
  VISIT_PRIME_SUBMISSION_COMPLETE: 'Visit Prime Submission Complete',
  WATER_LOGGING_CARD: 'Water Logging Card',
  WEEKLY: 'Weekly',
  WEIGHT_DETAILED_SCREEN: 'Weight Detailed Screen',
  WEIGHT_LOG_CARD: 'Weight Log Card',
  WEIGHT_LOG_SCREEN: 'Weight Log Screen',
  WHATSAPP_PERMISSION_CARD: 'Whatsapp Permission Card',
  WHATSAPP_PERMISSION_SCREEN: 'Whatsapp Permission Screen',
  COVID_GP_SELECTED: 'Covid GP Selected',
  COVID_HOME_CARE_ACTIVE_PAGE: 'Covid Home Care Active Page',
  COVID_HOME_CARE_SELECTED: 'Covid Home Care Selected',
  COVID_HOME_PAGE: 'Covid Home Page',
  COVID_SPECIALIST_SELECTED: 'Covid Specialist Selected',
  VISIT_COVID_HOME_CARE_ADD_RELATIVE: 'Visit Covid Home Care Add Relative',
  VISIT_COVID_HOME_CARE_HOMEPAGE: 'Visit Covid Home Care Homepage',
  VISIT_COVID_HOME_CARE_ORDER_COMPLETION_SCREEN:
    'Visit Covid Home Care Order Completion Screen',
  VISIT_COVID_HOME_CARE_RELATIVE_SAVED: 'Visit Covid Home Care Relative Saved',
  VISIT_COVID_HOME_CARE_RELATIVE_SELECTED:
    'Visit Covid Home Care Relative Selected',
  VISIT_COVID_HOME_CARE_SUBMISSION_COMPLETE:
    'Visit Covid Home Care Submission Complete',
  CONSULT_A_COVID_19_SPECIALIST: 'Consult a COVID 19 Specialist',
  MY_FITNESS_PROGRAM: 'My Fitness Program',
  PROGRAM_NOT_CREATED: 'Program Not Created',
  WORKOUT_PLAN_CREATED: 'Workout Plan Created',
  YOUR_WORKOUT_PLAN: 'Your Workout Plan',
};

export { logEvent, category, screenName };
